import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

const Blogposdt = () => {
    const [blogData, setBlogData] = useState([]);
    const [mainImage, setMainImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { Blogid } = useParams();

    useEffect(() => {
        fetchBlogData();
    }, [Blogid]);

    const fetchBlogData = async () => {
        try {
            setLoading(true); // Set loading state to true
            const apiUrl = `https://writers.gangsofgamer.com/api/articles/${Blogid}`;
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.status}`);
            }

            const data = await response.json();

            // Extract the content and the main image URL
            const mainData = JSON.parse(data.data.content);
            const mainImageUrl = data.data.jetpack_featured_media_url; // Access jetpack_featured_media_url

            setBlogData(mainData);
            setMainImage(mainImageUrl);
        } catch (err) {
            console.error("Error fetching blog data:", err);
            setError(err.message); // Set error message
        } finally {
            setLoading(false); // End loading state
        }
    };

    if (loading) {
        return <Typography variant="h6">Loading...</Typography>;
    }

    if (error) {
        return <Typography variant="h6" color="error">Error: {error}</Typography>;
    }

    return (
        <>
            <div>
                {/* Display the main image */}
                {mainImage && (
                    <Card>
                        <CardMedia
                            component="img"
                            alt="Main Image"
                            height="auto"
                            src={mainImage}
                        />
                    </Card>
                )}

                <div>
                    {/* Display the blog content */}
                    {blogData.blocks && blogData.blocks.map((block, index) => {
                        switch (block.type) {
                            case 'paragraph':
                                return (
                                    <Typography
                                        className="BlogTitle"
                                        key={index}
                                        variant="body1"
                                        dangerouslySetInnerHTML={{ __html: block.data.text }}
                                    />
                                );
                            case 'image':
                                return (
                                    <Card key={index}>
                                        <CardMedia
                                            component="img"
                                            alt="Image"
                                            height="auto"
                                            src={`https://writers.gangsofgamer.com${block.data.file.url}`}
                                        />
                                    </Card>
                                );
                            case 'LinkTool':
                                return (
                                    <Link
                                        key={index}
                                        to={block.data.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {block.data.link}
                                    </Link>
                                );
                            default:
                                return null; // Handle other block types as needed
                        }
                    })}
                </div>
            </div>
        </>
    );
};

export default Blogposdt;
