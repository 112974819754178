import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { ClassNames } from '@emotion/react';

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <div style={{ margin: "20px",  color: "rgb(255, 45, 85)" }}>
        <Link style={{ textDecoration: "none", color: "rgb(255, 45, 85)" }} > <h3>Quiz King</h3> </Link>

      </div>
      <Divider />

      {/* <List>
        {['Media', 'Blog'].map((text, index) => (
          <Link style={{ textDecoration: "none", color: "black" }} to={`/${text}`}>

            <ListItem key={text} disablePadding>
              <ListItemButton>

                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List> */}
      <Divider />
      <List>
        {['Privasy', 'About Us','Contect Us','Tearms Of Use', 'Legal'].map((text, index) => (

          <Link style={{ textDecoration: "none", color: "black" }} to={`/${text}`}>
            <ListItem key={text} disablePadding>
              <ListItemButton>

                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const pages = ['Products', 'Pricing', 'Blog'];

  return (
    <Box  sx={{ display: 'flex' }} class="navboxshood">
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ backgroundColor: "white", color: "rgb(255, 45, 85)" }}
        sx={{
          width: { sm: `calc(100% - ${mobileOpen && drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Link style={{ textDecoration: "none", color: "rgb(255, 45, 85)" }} to={"/"}>
            <Typography variant="h6" noWrap component="div">
            Game Quiz 
            </Typography>
          </Link>

       

        </Toolbar>
      </AppBar>
      {
        <Box
          component="nav"
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block'},
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>

        </Box>}

    </Box>
  );
}



export default ResponsiveDrawer;