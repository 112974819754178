const Contectus = () => {
    return (
        <>
            <main style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}} class="MuiBox-root css-1ezwkbm" ><div class="MuiToolbar-root MuiToolbar-gutters MuiToolbar-regular css-i6s8oy"></div><h1>About Us.</h1>
                <p>
                    "For any queries, you can contact us at this email address."

                </p>
                <span style={{backgroundColor:"red"}}>
                    support@freefire.upmspboard.com
                </span></main>
        </>
    )
}

export default Contectus