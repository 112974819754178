import React from "react";

const Aboutuse = () => {
    return (
        <>
        <main class="MuiBox-root css-1ezwkbm" ><div class="MuiToolbar-root MuiToolbar-gutters MuiToolbar-regular css-i6s8oy"></div><h1>About Us.</h1><p>freefire.upmspboard.com is a online fun website to play movie quiz and guessing quiz. Here you can create couple quiz to test how well your partner knows you as well as quiz for your friends. In addition to these personal quizes you can also play movie quizes and you can even challenge your friends in these movie quizes. At the end of your movie quiz you will get personalize link to create competetion and challenge your friends. You will be able to see your scores as well as your friends scores. This is fun website made for everyone with love. Thank you!</p><span></span></main>
        </>
    )
}

export default Aboutuse