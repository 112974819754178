import React from "react"
import Friend from "../asssets/26829956.jpg"
import Coupel from "../asssets/22507028.jpg"
import Mindcarft from "../asssets/Mindcarft.jpg"

import Awm from "../asssets/Awm.mp3"
import Gamepsotet1 from "../asssets/2.jpg"
import Gamepsotet2 from "../asssets/3.jpg"
import Gamepsotet3 from "../asssets/4.jpg"
import An94 from "../asssets/An94_Gun.mp3"
import Augun from "../asssets/Aug_Gun.mp3"
import AK47 from "../asssets/Ak_Gun.mp3"
import Bat from "../asssets/Bat.mp3"
import CG15gun from "../asssets/CG15_gun.mp3"
import Desert_Eagle from "../asssets/Desert_Eagle.mp3"
import Draguanov from "../asssets/Draguanov.mp3"
import Famousoneshot from "../asssets/Famas_Normal.mp3"
import M1014 from "../asssets/M1014_Gun.mp3"
import M1014Head from "../asssets/M1014_Headshot.mp3"
import M14 from "../asssets/M14.mp3"
import M1813 from "../asssets/M1873.mp3"
import M4A1 from "../asssets/M4A1.mp3"
import M1873Headshot from "../asssets/M1887_Headshot.mp3"
import M249 from "../asssets/M249.mp3"
import M60 from "../asssets/M60.mp3"
import M82b from "../asssets/M82b.mp3"
import Mag7 from "../asssets/Mag_7_Gun.mp3"
import leage from "../asssets/League.jpeg"
import Frotnignt from "../asssets/Fornnight.jpeg"
import Valour from "../asssets/valour.jpeg"
export const Quizdata = [
  {
    Poster: <img src={Valour} />,
    id: 1,
    slang: "How good You know the Valorant"
  },
  {
    Poster: <img src={Mindcarft} />,
    id: 2,
    slang: "How good You know the Minecraft"
  },
  {
    Poster: <img src={Frotnignt} />,
    id: 4,
    slang: "How good You know the Frotnignt"
  },
  {
    Poster: <img src={leage} />,
    id: 3,
    slang: "How good You know the League of Legends"
  },

  {
    Poster: <img src={Gamepsotet3} />,
    id: 6,
    slang: "Guess the gun sound quiz challeng"
  },
  {
    Poster: <img src={Gamepsotet1} />,
    id: 5,
    slang: "Guess the gun sound quiz challeng"
  },
  {
    Poster: <img src={Gamepsotet2} />,
    id: 7,
    slang: "Guess the gun sound quiz challeng"
  },



]


export const Quizdaat = {
  7: [
    {
      Question: "This is my favorite gun in free fire, come on  guess it ",

      Audios: Awm,
      option: ["AWM", "Aug Gun", "An94 Gun", "AK-47 "],
      ans: "AWM"

    },
    {
      Question: "Can you identify gun name by this gun sound ? ",

      Audios: An94,
      option: ["AWM", "An94 Gun", "An94 Gun", "desart egal "],
      ans: "An94 Gun"

    },
    {
      Question: "Which gun sounds like this?",

      Audios: Augun,
      option: ["Aug Gun", "AWM", "woodpaker", "AK-47"],
      ans: "Aug Gun"

    },
    {
      Question: "Can you identify gun name by this gun sound ",

      Audios: AK47,
      option: ["CG15", "AWM", "AK-47", "Bat"],
      ans: "AK-47"

    },
    {
      Question: "Which free fire gun is this? ",

      Audios: Bat,
      option: ["pan", "CG15 100% charged", "AK-47", "Bat"],
      ans: "Bat"

    },
    {
      Question: "Which free fire gun is this? ",

      Audios: CG15gun,
      option: ["CG15gun", "Draguanov", "AK-47", "CG15 100% charged"],
      ans: "CG15gun"

    },
    {
      Question: "Which free fire gun is this? ",

      Audios: Desert_Eagle,
      option: ["kar98", "Famas Normal", "Desert Eagle", "Draguanov"],
      ans: "Desert Eagle"

    },
    {
      Question: "Do you know this gun sound?",

      Audios: Draguanov,
      option: ["Draguanov", "Famas one shot", "kar98-y", "CG15 100% charged"],
      ans: "Draguanov"

    },

  ],
  5: [
    {
      Question: "This is my favorite gun in free fire, come on  guess it ",

      Audios: Famousoneshot,
      option: ["Famas one shot", "Flame Thrower One Fire ", "Famas normal", "Fist"],
      ans: "Famas one shot"

    },
    {
      Question: "I bet you can't guess this gun by just sound?",

      Audios: M1014,
      option: ["M1014 Gun", "m1887", "M1014 Headshot", "M14"],
      ans: "M1014 Gun"

    },
    {
      Question: "Which free fire gun is this?      ",

      Audios: M1014Head,
      option: ["M14", "M1014 Headshot", "M1873", "m1014"],
      ans: "M1014 Headshot"

    },
    {
      Question: "This is my favorite gun in free fire, come on guess it! ",

      Audios: M14,
      option: ["M1873", "M14", "m1014", "M1873 Headshot"],
      ans: "M14"

    },
    {
      Question: "Do you know this gun sound?",

      Audios: M1813,
      option: ["M1813", "M500", "M4A1", "thomson"],
      ans: "M1813"

    },
    {
      Question: "Can you identify gun name by this gun sound?      ",
      Audios: M249,
      option: ["M249", "M1887 Fire", "M1873 Headshot ", "M1014 Headshot      "],
      ans: "M249"

    },
    {
      Question: "I bet you can't guess this gun by just sound?      ",

      Audios: M4A1,
      option: ["M249", "M60", "M4A1", "M500"],
      ans: "M4A1"

    },
    {
      Question: "I bet you can't guess this gun by just sound?      ",

      Audios: M1873Headshot,
      option: ["M1887 Headshot", "M249", "M10", "M4A1"],
      ans: "M1887 Headshot"

    },

  ],
  6: [
    {
      Question: "Which free fire gun is this? ",

      Audios: M60,
      option: ["M60", "M14", "M82b", "Mag 7 Gun"],
      ans: "M60"

    },
    {
      Question: "Can you guess this gun?",
      Audios: M82b,
      option: ["Mag 7 Headshot", "M82b", "Mag 7 Gun", "M82b"],
      ans: "M82b"

    },
    {
      Question: "Can you guess this gun?",

      Audios: Mag7,
      option: ["Mp40", "Mag 7 Headshot", "Mag 7 Gun", "M82b"],
      ans: "Mag 7 Gun"

    },
    {
      Question: "This is my favorite gun in free fire, come on  guess it ",

      Audios: Awm,
      option: ["AWM", "Aug Gun", "An94 Gun", "AK-47 "],
      ans: " "

    },

  ],
  2: [
    {
      Question: "What is the rarest ore in Minecraft?",
      option: ["Diamond", "Emerald", "Lapis Lazuli", "Redstone"],
      ans: "Emerald"
    },
    {
      Question: "Which mob in Minecraft drops the most experience points when killed?",
      option: ["Wither", "Ender Dragon", "Blaze", "Guardian"],
      ans: "Ender Dragon"
    },
    {
      Question: "What is the primary use of an Ender Pearl in Minecraft?",
      option: ["Fast travel", "Brewing", "Crafting", "Trading"],
      ans: "Fast travel"
    },
    {
      Question: "Which of these mobs can be tamed?",
      option: ["Creeper", "Zombie", "Wolf", "Skeleton"],
      ans: "Wolf"
    },
    {
      Question: "What do you need to mine obsidian?",
      option: ["Wooden Pickaxe", "Stone Pickaxe", "Iron Pickaxe", "Diamond Pickaxe"],
      ans: "Diamond Pickaxe"
    },
    {
      Question: "How many blocks of iron ore are needed to make one iron ingot?",
      option: ["One", "Two", "Three", "Four"],
      ans: "One"
    },
    {
      Question: "What do you need to craft a bed in Minecraft?",
      option: ["Wool and Wood", "Wool and Stone", "Feathers and Wood", "Feathers and Stone"],
      ans: "Wool and Wood"
    },
    {
      Question: "What can you use to light a Nether Portal?",
      option: ["Lighter", "Torch", "Flint and Steel", "Lava Bucket"],
      ans: "Flint and Steel"
    },
    {
      Question: "What material do you need to craft a bucket?",
      option: ["Wood", "Stone", "Iron", "Gold"],
      ans: "Iron"
    },
    {
      Question: "Which of these items cannot be used as fuel in a furnace?",
      option: ["Wood", "Coal", "Lava Bucket", "Iron Ore"],
      ans: "Iron Ore"
    }
  ],
  1: [
    {
      Question: "Which agent is known for setting up sentry turrets?",
      option: ["Cypher", "Killjoy", "Breach", "Sova"],
      ans: "Killjoy"
    },
    {
      Question: "What is the maximum number of players on each team in a standard Valorant match?",
      option: ["3", "4", "5", "6"],
      ans: "5"
    },
    {
      Question: "Which agent has the ability to heal teammates?",
      option: ["Phoenix", "Sage", "Brimstone", "Reyna"],
      ans: "Sage"
    },
    {
      Question: "What is the name of the in-game currency used to purchase weapons and abilities?",
      option: ["Valor", "Credits", "Radianite Points", "Gold"],
      ans: "Credits"
    },
    {
      Question: "Which map features the spike sites 'A', 'B', and 'C'?",
      option: ["Haven", "Split", "Bind", "Ascent"],
      ans: "Haven"
    },
    {
      Question: "Which agent is known for his teleportation abilities?",
      option: ["Omen", "Jett", "Yoru", "Raze"],
      ans: "Omen"
    },
    {
      Question: "What is the name of Phoenix's ultimate ability?",
      option: ["Blaze", "Run It Back", "Hot Hands", "Curveball"],
      ans: "Run It Back"
    },
    {
      Question: "Which agent's abilities include deploying poison gas clouds?",
      option: ["Viper", "Breach", "Cypher", "Reyna"],
      ans: "Viper"
    },
    {
      Question: "Which weapon in Valorant is a one-shot kill to the head, regardless of armor?",
      option: ["Operator", "Phantom", "Vandal", "Sheriff"],
      ans: "Operator"
    },
    {
      Question: "What role does the agent Sova primarily play in the game?",
      option: ["Duelist", "Initiator", "Controller", "Sentinel"],
      ans: "Initiator"
    }
  ],
  4: [
    {
      Question: "What is the name of the in-game currency used in Fortnite?",
      option: ["V-Coins", "V-Bucks", "F-Coins", "F-Bucks"],
      ans: "V-Bucks"
    },
    {
      Question: "What is the maximum number of players in a standard Fortnite battle royale match?",
      option: ["50", "100", "150", "200"],
      ans: "100"
    },
    {
      Question: "What does the 'Storm' do in Fortnite?",
      option: ["Heals players", "Expands the map", "Shrinks the safe zone", "Gives players more health"],
      ans: "Shrinks the safe zone"
    },
    {
      Question: "Which of the following locations is NOT a named area in Fortnite?",
      option: ["Pleasant Park", "Salty Springs", "Lucky Landing", "Mysterious Meadows"],
      ans: "Mysterious Meadows"
    },
    {
      Question: "What season introduced the 'Zero Point' event in Fortnite?",
      option: ["Season 4", "Season 5", "Season 6", "Season 7"],
      ans: "Season 5"
    },
    {
      Question: "In which Fortnite season was the 'Battle Pass' first introduced?",
      option: ["Season 1", "Season 2", "Season 3", "Season 4"],
      ans: "Season 2"
    },
    {
      Question: "What was the name of the first crossover event with Marvel in Fortnite?",
      option: ["Infinity War", "Endgame", "Nexus War", "Galactus Invasion"],
      ans: "Infinity War"
    },
    {
      Question: "Which weapon was vaulted (removed from the game) in Season 10, much to the community's dismay?",
      option: ["Pump Shotgun", "Drum Gun", "Tactical Shotgun", "SCAR"],
      ans: "Pump Shotgun"
    },
    {
      Question: "In Fortnite lore, who is the leader of the 'Seven'?",
      option: ["The Scientist", "The Visitor", "The Paradigm", "The Foundation"],
      ans: "The Foundation"
    },
    {
      Question: "What unique feature did the 'Kevin the Cube' bring to the game?",
      option: ["It spawned zombies", "It caused gravity distortions", "It created shield potions", "It opened rifts in the sky"],
      ans: "It caused gravity distortions"
    }
  ],
  3: [
    {
      Question: "Who is known as 'The Sinister Blade'?",
      option: ["Ahri", "Katarina", "Akali", "LeBlanc"],
      ans: "Katarina"
    },
    {
      Question: "What is the maximum level a champion can reach in a standard game?",
      option: ["15", "18", "20", "25"],
      ans: "18"
    },
    {
      Question: "Which champion can place a 'Sightstone' item?",
      option: ["Thresh", "Blitzcrank", "Janna", "Bard"],
      ans: "Thresh"
    },
    {
      Question: "Which dragon grants a stacking buff that enhances ability power and attack damage?",
      option: ["Mountain Drake", "Infernal Drake", "Ocean Drake", "Cloud Drake"],
      ans: "Infernal Drake"
    },
    {
      Question: "What is the name of the region where Ashe and Sejuani hail from?",
      option: ["Noxus", "Demacia", "Freljord", "Ionia"],
      ans: "Freljord"
    },
    {
      Question: "Who was the first champion released in League of Legends?",
      option: ["Twisted Fate", "Ashe", "Ryze", "Warwick"],
      ans: "Twisted Fate"
    },
    {
      Question: "Which item grants the 'Spellblade' passive effect?",
      option: ["Luden's Echo", "Hextech Gunblade", "Lich Bane", "Nashor's Tooth"],
      ans: "Lich Bane"
    },
    {
      Question: "What is the name of the legendary creature that grants a powerful buff upon being defeated?",
      option: ["Rift Herald", "Baron Nashor", "Elder Dragon", "Blue Sentinel"],
      ans: "Baron Nashor"
    },
    {
      Question: "Which champion can change forms between a fox and a human?",
      option: ["Elise", "Nidalee", "Ahri", "Xayah"],
      ans: "Ahri"
    },
    {
      Question: "What is the maximum number of wards a player can have on the map at once?",
      option: ["2", "3", "4", "5"],
      ans: "3"
    }
  ]







}






export const MainQuizquestionHindi = {

  friend: [
    { question: "हमारी दोस्ती को सबसे अच्छी तरह से कौन सी फिल्म दर्शाती है?", options: ["दिल चाहता है", "3 इडियट्स", "शोले", "ये जवानी है दीवानी"] },
    { question: "हमारी पहली मिलकर के लिए कहाँ गई थी?", options: ["स्कूल", "खुले में", "कक्षा", "कॉलेज"] },
    { question: "हमारा पसंदीदा स्ट्रीट फूड क्या है?", options: ["पानी पूरी", "वड़ा पाव", "चाट", "समोसा"] },
    { question: "हम साथ मनाने के लिए किस त्योहार का आनंद लेते हैं?", options: ["दिवाली", "होली", "गणेश चतुर्थी", "नवरात्रि"] },
    { question: "हम साथ मिलकर एक साथ खोजने के लिए किस भारतीय शहर को पसंद करेंगे?", options: ["जयपुर", "वाराणसी", "गोवा", "मुंबई"] },
    { question: "हम एक साथ जाने के लिए किस भारतीय ऐतिहासिक स्थल को देखना चाहेंगे?", options: ["ताज महल", "लाल किला", "हम्पी", "कुतुब मीनार"] },
    { question: "हमारा बॉलीवुड नृत्य का बेस्ट मूव क्या है?", options: ["भांगड़ा", "गरबा", "दांडिया", "जुम्बा"] },
    { question: "किस भारतीय परंपरा का हमारे दोस्ती के साथ संबंध है?", options: ["रक्षा बंधन", "नवरात्रि", "ओणम", "ईद"] },
    { question: "मेरा पसंदीदा भारतीय मिठाई क्या है?", options: ["गुलाब जामुन", "रसगुल्ला", "जलेबी", "खीर"] },
  ],

};

export const MainQuizquestion =
{
  freefire: [
    { question: "Which Free Fire character is Your favorite?", options: ["Alok", "Kelly", "Moco", "Andrew"] },
    { question: "What's your highest kill count in a single Free Fire match?", options: ["Less than 5", "5-10", "11-15", "More than 15"] },
    { question: "Which Free Fire weapon skin would you love to have?", options: ["Dragon AK", "Cupid Scar", "Pharaoh MP40", "Evil Pumpkin M1887"] },
    { question: "What's your preferred Free Fire playing style?", options: ["Rushing", "Sniping", "Supporting", "Surviving"] },
    { question: "Which Free Fire pet do you like the most?", options: ["Falco", "Ottero", "Robo", "Poring"] },
    { question: "What's your favorite Free Fire emote?", options: ["Booyah!", "Dab", "K.O.", "Thumbs Up"] },
    { question: "Which Free Fire vehicle do you prefer to use?", options: ["Motorcycle", "Jeep", "Tuk Tuk", "Monster Truck"] },
    { question: "What's your highest rank achieved in Free Fire?", options: ["Bronze", "Silver", "Gold", "Diamond or Above"] },
  ],
  friend: [

    { question: "Which movie best describes our friendship?", options: ["Dil Chahta Hai", "3 Idiots", "Sholay", "Yeh Jawaani Hai Deewani"] },
    { question: "Where was our first hangout in?", options: ["School", "on Ground", "Class", "collage"] },
    { question: "What's our favorite  street food?", options: ["Pani Puri", "Vada Pav", "Chaat", "Samosa"] },
    { question: "What festival do we enjoy celebrating together?", options: ["Diwali", "Holi", "Ganesh Chaturthi", "Navrari"] },
    { question: "Which Indian city would we love to explore together?", options: ["Jaipur", "Varanasi", "Goa", "Mumbai"] },
    { question: "Which Indian historical place would we like to visit?", options: ["Taj Mahal", "Red Fort", "Hampi", "Qutub Minar"] },
    { question: "What's our go-to Bollywood dance move?", options: ["Bhangra", "Garba", "Dandiya", "Zumba"] },
    { question: "Which Indian tradition resonates with our friendship?", options: ["Raksha Bandhan", "Navratri", "Onam", "Eid"] },
    { question: "What's my favorite Indian dessert?", options: ["Gulab Jamun", "Rasgulla", "Jalebi", "Kheer"] },


  ],
  couple:
    [
      { question: 'What is your favorite TV show?', options: ['Anupamaa', 'Yeh Rishta Kya Kehlata Hai', 'Yeh Hai Chahatein', 'Taarak Mehta Ka Ooltah Chashmah'] },

      { question: 'What is your dream holiday?', options: ['A trip to Maldives', 'Camping in the forest', 'Touring the country', 'Lying on an exotic beach'] },
      { question: 'What is afraid of the most you?', options: ['Speed Racing', 'Spiders', 'Lizard', 'Lightning strikes'] },


      {
        question: 'What is your favorite Bollywood movie genre?',
        options: [
          'Romantic',
          'Action',
          'Comedy',
          'Drama',
          'Thriller',
        ],
      },
      {
        question: 'Which place in India would You love to visit the most?',
        options: [
          'Goa',
          'Himachal Pradesh',
          'Kerala',
          'Rajasthan',
          'Andaman and Nicobar Islands',
        ],
      },
      {
        question: 'What hobby makes you happy??',
        options: [
          'Reading book',
          'Playing Game',
          'Riding Cars',
          'Coocking',
          'Chaat',
        ],
      },
      {
        question: "Where would your partner like to live in the future?",
        options: [
          'In city ',
          'In Village',
          'Out of Country',
          'Home Town',
        ],
      },
      {
        question: "When is your Birthday",
        options: [
          'Oct 23',
          'jan 10 ',
          'sep 7',
          'dec 22',
        ],
      },

      {
        question: " What color does your partner like the Most?",
        options: [
          'Green',
          'black',
          'Pink',
          'Blue',
        ],
      },

    ]

}





export const PreshowofQuiz = {
  freefire: {
    img: <img className="Photos" src={Friend} />,
    name: "Free Fire Quiz",
    question: "How well your friends know About Your free fire game?",
  },
  friend: {
    img: <img className="Photos" src={Friend} />,
    name: "Best friend Quiz",
    question: "How well do your friends know you?",
  },
  couple: {
    img: <img className="Photos" src={Coupel} />,
    name: 'Couple Quiz',
    question: "How well do your Partner know you?"
  },

}






